import React, { useEffect, useMemo } from "react";
import LocaProgressBar from "./ProgressBar";
import { Link } from "react-router-dom";
import { AiOutlineLink } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { DragButton } from "../elements/dragButton";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { FundedPercentage, imgBasePath } from "../utils/Helper";
import useConvertedAmounts from "../services/useGoalsCurrencyConverterHook";
import Tooltip from "./TootlTip";
import useExchangeRate from "../services/useExchangeRate";
import useCurrencySymbol from "../services/useCurrencySymbol";
import useAuth from "../services/useAuth";
import usePostTweet from "../services/usePostTweet.js";
import copyToCLipboard from "../utils/copyToClipboard.js";
import noImage from "../svgfile/no-photos.svg"

const Goals = ({ goals, currency, currencyDetails }) => {
  const [postTweet] = usePostTweet();
  const { user: authUser } = useAuth();
  const user = useMemo(() => JSON.parse(authUser), [authUser]);
  const convertedAmounts = useConvertedAmounts(goals, currency);
  const userCurrency = useCurrencySymbol(user?.currency);
  const [getExchangeRate, exchangeRates, exchangeLoading] = useExchangeRate();
  useEffect(() => {
    if (userCurrency?.short_code) {
      getExchangeRate(userCurrency?.short_code, "USD");
    }
  }, [userCurrency?.short_code]);
  return (
    <Droppable droppableId="goalCard" direction="horizontal">
      {(provided, snapshot) => (
        <section
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="goal-card-section"
        >
          {goals?.map((goal, index) => (
            <Draggable
              key={goal?._id}
              draggableId={goal?._id?.toString()}
              index={index}
            >
              {(provided) => (
                <div
                  className="card goal-card"
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                >
                  <div
        
                    state={{ goalId: goal?._id }}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <Link
                      to={`/fighter/goal-detail/${goal?._id}`}
                      className="goal-car-img position-relative"
                    >
                      {goal?.status === "active" ? (
                        <span className="badge text-bg-success goal-status">
                          active
                        </span>
                      ) : (
                        <span className="badge text-bg-warning goal-status">
                          inactive
                        </span>
                      )}

                      {/* card-drag-con start */}
                      <div className="card-drag-icon">{DragButton}</div>
                      {/* card-drag-con end */}
                      <img
                        src={`${imgBasePath}/${goal?.goalImage}`}
                        onError={(e)=>{
                          return e.target.src = noImage
                        }}
                        className="card-img-top"
                        alt="loading"
                      />
                    </Link>
                    <div className="card-body">
                      
                        <Link  to={`/fighter/goal-detail/${goal?._id}`} className="text-decoration-none">
                        <h5 className="goal-title">
                        {goal?.goalName?.slice(0, 15)}{" "}
                      </h5>
                      <div className="price-plan-subscription">
                        <h5 className="goal-price-typo">
                          {currency}
                          {currencyDetails?.symbol}
                          {parseFloat(goal?.goalPrice).toFixed(2)}{" "}
                          <Tooltip
                            content={
                              !exchangeLoading
                                ? `Estimated Conversion From USD $${(
                                    exchangeRates?.USD * goal?.goalPrice
                                  ).toFixed(2)}`
                                : "Estimated Conversion From USD $ XXX.."
                            }
                          >
                            <i className="fa fs-6 pointer" role="button">
                              &#xf059;
                            </i>
                          </Tooltip>
                        </h5>
                        {goal?.goalType === "subscription" &&
                          goal?.subscriptionType && (
                            <button className="freq-btn medium-typo">
                              {goal?.subscriptionType.length > 1
                                ? "Subscribable"
                                : goal?.subscriptionType[0]}
                            </button>
                          )}
                      </div></Link>
                      <div>
                        {/* if goal type is crowd then show the crowd funded amount */}
                        {goal?.goalType === "crowd" ? (
                          <div className="goal-progressbar">
                            <LocaProgressBar
                              totalPercent={FundedPercentage(
                                goal.TotalCrowdFunded,
                                goal.goalPrice
                              )}
                            />
                          </div>
                        ) : (
                          <div className="prog"></div>
                        )}

                        <div className="goal-links">
                          <button className="text-dark gift-copy-btn"
                          onClick={()=> copyToCLipboard(`goal-detail/${goal?._id}`)}
                          >
                            <AiOutlineLink size={20} />
                          </button>
                          <button
                            className="d-flex  text-dark text-dec-non x-postbtn"
                            onClick={() =>
                              postTweet(`${imgBasePath}/${goal?.goalImage}`)
                            }
                          >
                            <FaXTwitter size={22} />
                            Post
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </section>
      )}
    </Droppable>
  );
};

export default Goals;
