import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import axios from "axios";
import useAuth from "../services/useAuth";
const Footer = () => {
  const [footerLinks, setFooterLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const auth = useAuth();
  const user = JSON.parse(auth?.user);
  // console.log(user.role);
  const fetchFooter = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_API_URL}/admin/cms-pages/`
      );
      // console.log("Fetched footer data:", response.data);
      if (response.data.status === "success" && response.data.pages) {
        const links = response.data.pages.map((page) => {
          if (user.role === "companion") {
            return {
              path: `/companion/f/${page.page_name
                .toLowerCase()
                .replace(/\s+/g, "-")}?id=${page._id}`,
              label: page.page_name,
            };
          } else if (user.role === "fighter") {
            return {
              path: `/fighter/f/${page.page_name
                .toLowerCase()
                .replace(/\s+/g, "-")}?id=${page._id}`,
              label: page.page_name,
            };
          } else if (user.role !== "companion" && user.role !== "fighter") {
            console.log("User not found...", user);
            return {
              path: `/f/${page.page_name
                .toLowerCase()
                .replace(/\s+/g, "-")}?id=${page._id}`,
              label: page.page_name,
            };
          }
        });
        setFooterLinks(links);
      } else {
        console.error("Unexpected data format:", response.data);
      }
    } catch (err) {
      console.error("Error fetching footer links:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchFooter();
  }, []);
  if (error) return <div>Error: {error.message}</div>;
  return (
    <>
      <div className="footer-container">
        <ul className="footer-ul footer-fonts">
          {footerLinks.map((link, index) => (
            <li key={index}>
              <Link to={link.path} target="_blank">
                {link.label}
              </Link>
            </li>
          ))}
            <li>
          {" "}
          <Link to="/help-center"  target="_blank">
            Help Center
          </Link>{" "}
        </li>
        <li>
          {" "}
          <Link to="/faq" target="_blank">
            FAQ
          </Link>
        </li>
        <li>
          <Link to="/">© 2024 Fight Companion Inc.</Link>
        </li>
        </ul>
      </div>
      <ToastContainer
        className="custom-toast-container"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </>
  );
};
export default Footer;