import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BackBTn } from "../elements/SvgElements";
import { AuthApi } from "../config/axiosUtils";
import { toast } from "react-toastify";

const OtpVerification = (props) => {
  const {
    handleSubmit,
    errors,
    forgotPassword,
    loading,
    handleOtpInput,
    handleOtpKeyup,
    otpRefs,
    handleOtpPaste,
    isOtpComplete,
    resetPassword,
    setResetPassword
  } = props;

  const [timeLeft, setTimeLeft] = useState(60); // 60 seconds countdown
  const [resendEnabled, setResendEnabled] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const submitResendOtp = async () => {
    try {
      setResendOtp(true);
      setTimeLeft(60); // Reset timer to 60 seconds
      setResendEnabled(false);
      setResetPassword({ ...resetPassword, prevOtpTime: new Date().getTime() });
      const response = await AuthApi.forgotPassword(resetPassword);
      // console.log(response);
      toast.success(response?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      // console.log(error);
    } finally {
      setResendOtp(false);
    }
  };

  useEffect(() => {
    const prevTime = resetPassword.prevOtpTime || new Date().getTime();
    const timeDifference = Math.floor((new Date().getTime() - prevTime) / 1000);
    const initialTimeLeft = Math.max(60 - timeDifference, 0);

    setTimeLeft(initialTimeLeft);
    setResendEnabled(initialTimeLeft <= 0);

    const timer =
      initialTimeLeft > 0 &&
      setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft <= 1) {
            clearInterval(timer);
            setResendEnabled(true);
            return 0;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);

    return () => clearInterval(timer);
  }, [resetPassword.prevOtpTime]);



  return (
    <>
      <span className="back-i-btn">
        <button className="backbtn" type="button">
          <Link to="..">{BackBTn}</Link>
        </button>
      </span>
      <div className="login-view mt-4 text-left">
        <h2 className="sub-head">
          OTP code verification<small className="fs"> 🔐</small>
        </h2>
        <p className="para mt-2 text-left">
          We have sent an OTP code to your email{" "}
          <strong>{resetPassword.email}</strong> Enter the OTP code below to
          verify.
        </p>
      </div>
      <form className="user-login-form" onSubmit={handleSubmit(forgotPassword)}>
        <div className="form-start-login">
          <div className="form-brand">
            <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
              <div className="form-group text-left">
                <div className="otp-input-container">
                  {Array(4)
                    .fill(0)
                    .map((_, index) => (
                      <input
                        key={index}
                        ref={otpRefs[index]}
                        type="tel"
                        maxLength="1"
                        className={`otp-input ${
                          errors[`otp${index + 1}`] && "otp-error"
                        }`}
                        onInput={(e) => handleOtpInput(e, index)}
                        onKeyUp={(e) => handleOtpKeyup(e, index)}
                        onPaste={(e) => handleOtpPaste(e, index)}
                        aria-label={`${index + 1} digit`}
                      />
                    ))}
                </div>
                <div className="text-center">
                  <p>Didn't receive the email?</p>
                  <p>
                    You can resend code in{" "}
                    <strong>{timeLeft > 0 && timeLeft + " s"}</strong>{" "}
                    {resendEnabled && (
                      <button
                        className="opt-resend-btn"
                        onClick={submitResendOtp}
                        disabled={!resendEnabled || resendOtp}
                        type="button"
                      >
                        Resend
                      </button>
                    )}
                  </p>
                </div>
                {errors?.email && (
                  <small className="error-message">
                    {errors.otp1?.message ||
                      errors.otp2?.message ||
                      errors.otp3?.message ||
                      errors.otp4?.message}
                  </small>
                )}
              </div>
              <div className="account-switch">
                <button
                  className={`long-btn-sign-in mt-5 mx-auto ${
                    loading ? "long-btn-sign-in-disabled" : null
                  }`}
                  disabled={loading || !isOtpComplete || resendOtp}
                  type="submit"
                >
                  {!loading || !resendOtp ? "Continue" : "loading..."}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default OtpVerification;
