import { useEffect, useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
import { BiSolidPencil } from "react-icons/bi";
import { GoPencil } from "react-icons/go";
import profilePic from "../img/userProfile.png";
import { Link, useSearchParams } from "react-router-dom";
import SocialLinkModal1 from "../components/SocialLinkModal1";
import Goals from "../components/Goals";
import { DragDropContext } from "react-beautiful-dnd";
import { fighterApi, userApi } from "../config/axiosUtils";
import useAuth from "../services/useAuth";
import { toast } from "react-toastify";
import { filterIcon } from "../elements/SvgElements";
import { useCategory } from "../features/categoryHooks.jsx";
import { Dropdown, Spinner } from "react-bootstrap";
import castDate, { getSocialIcon, imgBasePath } from "../utils/Helper.js";
import { updateProfile } from "../features/authSlice.js";
import { useDispatch, useSelector } from "react-redux";
import copyToCLipboard from "../utils/copyToClipboard.js";

const MainContent = () => {
  const categories = useCategory();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false)
  const auth = useAuth();
  const user = JSON.parse(auth?.user);
  const [currencyDetails, setCurrencyDetails] = useState(null);
  const dispatch = useDispatch();
  const [showSocilMdl1, setShowSocilMdl1] = useState(false);
  const [bannerpreveImage, setBannerPreviewImage] = useState(null);
  const currency = useSelector((state) => state?.currency?.currency);
  const [profileprevImage, setprofilePreviewImage] = useState(null);
  const [data, setData] = useState({
    goals: [],
    status: "",
    message: "",
  });
  const onDragEnd = async (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let active = [...data.goals];
    let add = active[source.index];
    let dup = { ...add };
    add.goalOrderBy = active[destination.index].goalOrderBy;
    active[destination.index].goalOrderBy = dup.goalOrderBy;
    active.splice(source.index, 1);
    active.splice(destination.index, 0, add);

    const payLoadDataof = active.map((item, index) => ({
      goalId: item._id,
      goalOrderBy: index + 1,
    }));

    const originalData = data.goals;
    setData((prevData) => ({
      ...prevData,
      goals: active,
    }));
    try {
      const res = await fighterApi.ChangeOrder(JSON.parse(auth?.token), {
        goals: payLoadDataof,
      });
      if (res?.status !== 200) {
        setData((prevData) => ({
          ...prevData,
          goals: originalData,
        }));
      }
    } catch (e) {
      setData((prevData) => ({
        ...prevData,
        goals: originalData,
      }));
      toast.error(e?.response?.data?.message || e?.response?.data?.error);
    }
  };

  const handeProfileUpdate = async (e, caller) => {
    const file = e.target.files[0];
    // File must be only image of jpeg/png type 
    if (file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "image/jpg") {
      return toast.error("Invalid file type. Allowed image types are png, jpg, jpeg.");
    }else if (file.size > 2 * 1024 * 1024) {
      return toast.error("File size must be less than 2MB");
    }

    setLoading(true)
    let formData = new FormData();
    if (caller === "bannerImage") {
      setBannerPreviewImage(URL.createObjectURL(file));
      formData.append("bannerImage", file);
    } else {
      setprofilePreviewImage(URL.createObjectURL(file));
      formData.append("profileImage", file);
    }

    try {
      const res = await userApi.updateProfile(
        formData,
        JSON.parse(auth?.token)
      );
      // console.log(res?.data?.data);
      // return;
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        dispatch(
          updateProfile({
            user: JSON.stringify(res?.data?.data),
          })
        );
        setBannerPreviewImage(null);
        setprofilePreviewImage(null);
      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setLoading(false)
    }
  };

  //function to load the user goals
  async function LoaderHomeData() {
    setLoading(true)
    try {
      let token = JSON?.parse(auth?.token);
      const res = await fighterApi.fetchHomeData(
        token,
        searchParams.get("category") || "",
        searchParams.get("sort") || ""
      );

      if (res?.status === 200) {
        const { data } = res?.data;
        // console.log(data);

        if (data?.length === 0) {
          setData({
            goals: [],
            message: res?.data?.message,
            status: res?.data?.status,
          });
          return;
        }

        setData({
          goals: data,
          message: res?.data?.message,
          status: res?.data?.status,
        });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error?.response?.data?.message);
        return;
      }

      // console.log(message);
      toast.error(error?.response?.data?.message);
    } finally {
      
      setLoading(false)
    }
  }

  //function to add filter data and type
  function handleFilter(filterType, value) {
    setSearchParams((prev) => {
      if (filterType === "price-sort") {
        prev.set("sort", value);
      } else {
        prev.set("category", value);
      }
      return prev;
    });
  }

  useEffect(() => {
    LoaderHomeData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("sort"), searchParams.get("category")]);

  useEffect(() => {
    if (user) {
      const data = currency?.find((item) => item.short_code === user.currency);
      setCurrencyDetails(data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.currency]);

  return (
    <>
      <main className="main-content">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
            <div className="tile">
              <div className="tile-content">
                <div className="user-banner-image">
                  {user?.bannerImage || bannerpreveImage ? (
                    <img
                      loading="lazy"
                      alt="banner"
                      srcSet={
                        bannerpreveImage
                          ? bannerpreveImage
                          : `${imgBasePath}/${user?.bannerImage}`
                      }
                      className="img u-banner"
                    />
                  ) : (
                    <div className="empty-bannerImage"></div>
                  )}

                  <div className="banner-img-picker-background">
                    <div className="banner-img-picker">
                      <input
                        type="file"
                        multiple={false}
                        disabled={loading}
                        onChange={(e) => handeProfileUpdate(e, "bannerImage")}
                      />
                      <span className="picker-icon typo-grey-16">
                        Edit
                        <BiSolidPencil size={21} color="white" />
                      </span>
                    </div>
                  </div>

                  <div className="user-profile-container">
                    <img
                      src={
                        profileprevImage
                          ? profileprevImage
                          : user?.profileImage
                          ? `${imgBasePath}/${user?.profileImage}`
                          : profilePic
                      }
                      alt=""
                    />
                    <div className="img-picker-container">
                      <input
                        type="file"
                        multiple={false}
                        onChange={(e) => handeProfileUpdate(e, "profileImage")}
                        disabled={loading}
                      />

                      <span className="picker-icon">
                        Edit
                        <BiSolidPencil color="white" />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="user-info">
                  <div className="user-content">
                    <div className="user-details">
                      <div className="user-name sec-head">
                        <span className="text-capitalize">
                          {user?.firstName} {user?.lastName}
                        </span>
                        {/* for mobile */}
                        <div className="d-lg-none d-mb-block">
                          <div className="gap-1 align-items-center">
                            {user?.socialLinks?.map((link) => (
                              <Link
                                to={link?.link}
                                key={link._id}
                                target="_blank"
                                className="social-media-links"
                              >
                                {getSocialIcon(link?.platform)}
                              </Link>
                            ))}

                            {user?.socialLinks?.length === 0 ? (
                              <span
                                role="button"
                                className="text-decoration-underline"
                                onClick={() => setShowSocilMdl1(true)}
                              >
                                Add Social Links
                              </span>
                            ) : null}
                            <GoPencil
                              size={20}
                              className="social-picker"
                              onClick={() => setShowSocilMdl1(true)}
                            />
                          </div>
                          {/* for mobile */}
                        </div>
                      </div>

                      <div className="user-handle">{user?.userName}</div>
                      <div className="user-link profile-details">
                        https://www.fightcompanion.io/{user?.userName}
                      </div>
                      <div className="promotion-company profile-details">
                        Fight Promotion Company: {user?.promotionCompany}
                      </div>
                    </div>
                    <div className="profilelink-copy-btn">
                      <IoCopyOutline
                        className="img-2"
                        onClick={() => copyToCLipboard(user?.userName)}
                      />
                    </div>
                  </div>

                  <div className="log-user-info-right d-mb-none">
                    <div className="fighter-top text-end">
                      <Link to="favourite" className="follow-button fav-btn">
                        Favorites
                      </Link>
                    </div>
                    <div className="fighter-bottom d-flex gap-2 flex-column">
                      <div className="d-flex align-item-center gap-1">
                        <FaRegCalendarAlt size={23} /> Joined{" "}
                        {castDate(user?.createdAt)}
                      </div>

                      <div className="d-flex gap-1 align-items-center justify-content-end">
                        {user?.socialLinks?.map((link) => (
                          <Link
                            to={link?.link}
                            key={link._id}
                            target="_blank"
                            className="social-media-links"
                          >
                            {getSocialIcon(link?.platform)}
                          </Link>
                        ))}

                        {user?.socialLinks?.length === 0 ? (
                          <span
                            role="button"
                            className="text-decoration-underline"
                            onClick={() => setShowSocilMdl1(true)}
                          >
                            Add Social Links
                          </span>
                        ) : null}
                        <GoPencil
                          size={20}
                          className="social-picker"
                          onClick={() => setShowSocilMdl1(true)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* for mobile favourite date of join detail */}
                <div className="d-flex d-lg-none d-mb-block f-mb-detail">
                  {/* join date */}
                  <div className="align-item-center gap-1">
                    <FaRegCalendarAlt size={23} /> Joined{" "}
                    {castDate(user?.createdAt)}
                  </div>
                  <div className="">
                    <Link to="favourite" className="follow-button fav-btn">
                      Favorites
                    </Link>
                  </div>
                </div>
                {/* for mobile favourite detail end  */}
              </div>
              <div className="filter-goal">
                <div className="filterLeft ">
                  <Dropdown>
                    <Dropdown.Toggle id="category-dropdown" variant="none">
                      <div
                        type="button"
                        className="category-btn sub-btn-fonts"
                        role="button"
                      >
                        Category
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu category-dropdown">
                      {categories?.map((element) => (
                        <Dropdown.Item
                          key={element?._id}
                          className={`dropdown-item ${
                            searchParams?.get("category") ===
                              element?.categoryName && "slected-filter"
                          }`}
                          role="button"
                          onClick={() => {
                            handleFilter("category", element?.categoryName);
                          }}
                        >
                          {element.categoryName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown>
                    <Dropdown.Toggle id="filter-dropdown" variant="none">
                      <div
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {filterIcon}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu category-dropdown">
                      <Dropdown.Item
                        className={`dropdown-item ${
                          searchParams?.get("sort") === "Default" &&
                          "slected-filter"
                        }`}
                        role="button"
                        onClick={(e) => {
                          handleFilter("price-sort", e?.target?.innerHTML);
                        }}
                      >
                        Default
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`dropdown-item ${
                          searchParams?.get("sort") === "High to Low" &&
                          "slected-filter"
                        }`}
                        role="button"
                        onClick={(e) => {
                          handleFilter("price-sort", "High to Low");
                        }}
                      >
                        Price: High to Low
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`dropdown-item ${
                          searchParams?.get("sort") === "Low to High" &&
                          "slected-filter"
                        }`}
                        role="button"
                        onClick={(e) => {
                          handleFilter("price-sort", "Low to High");
                        }}
                      >
                        Price: Low to High
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`dropdown-item ${
                          searchParams?.get("sort") === "Most Recent" &&
                          "slected-filter"
                        }`}
                        role="button"
                        onClick={(e) => {
                          handleFilter("price-sort", e?.target?.innerHTML);
                        }}
                      >
                        Most Recent
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`dropdown-item ${
                          searchParams?.get("sort") === "Oldest" &&
                          "slected-filter"
                        }`}
                        role="button"
                        onClick={(e) => {
                          handleFilter("price-sort", e?.target?.innerHTML);
                        }}
                      >
                        Oldest
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="rightgoal all-btn">
                  <Link to="create-goal">Create Goal</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {/* card section-start */}

          {data?.goals?.length === 0 && !loading ? (
            <div className="text-center">There is No Goal Found !</div>
          ) : (
            <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
              <DragDropContext onDragEnd={onDragEnd}>
                <Goals
                  goals={data?.goals}
                  currency={user?.currency}
                  currencyDetails={currencyDetails}
                />
              </DragDropContext>
            </div>
          )}

          {/* card section-end-here */}
        </div>
      </main>
      <SocialLinkModal1
        showSocilMdl1={showSocilMdl1}
        setShowSocilMdl1={setShowSocilMdl1}
        socialLink={user?.socialLinks}
      />
      {loading && (
        <div className="fighter-home-spinner">
          <Spinner animation="border" variant="danger" />
        </div>
      )}
    </>
  );
};

export default MainContent;
